/* eslint-disable react/no-danger */

import classNames from "classnames";
import isString from "lodash/isString";
import React from "react";

import RawContent from "components/common/RawContent";
import { ApiHelpInfosGet } from "services/api/helpInfos";
import { useAppSelector } from "stores";
import { selectPrivateKey } from "stores/features/project";
import { useHelpInfo } from "utils/hooks";
import { Paths } from "utils/objects";

import styles from "./styles.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  configKey?: Paths<ApiHelpInfosGet>;
}

const Info: React.FC<Props> = ({ children, className, configKey = "" }) => {
  const [helpInfoMessage] = useHelpInfo(configKey);
  const privateKey = useAppSelector(selectPrivateKey);

  if (children && !isString(children)) {
    return <div className={classNames(styles.info, className)}>{children}</div>;
  }

  return (
    <RawContent
      className={classNames(
        styles.info,
        !privateKey && styles.newApp,
        className
      )}
    >
      {(children as string) || helpInfoMessage}
    </RawContent>
  );
};

export default Info;
