import classNames from "classnames";
import { decamelize } from "humps";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import urlJoin from "url-join";

import IconInfo from "assets/images/icons/helpInfo.svg";
import IconCopyLink from "assets/images/icons/IconCopyLink";
import Button from "components/common/Button";
import Info from "components/common/Info";
import { ApiHelpInfosGet } from "services/api/helpInfos";
import { useAppDispatch, useAppSelector } from "stores";
import { selectPrivateKey } from "stores/features/project";
import { uiShowDocsKeyUpdated, uiUpdated } from "stores/features/ui";
import {
  useAppSection,
  useBackupVersion,
  useCopyToClipboard,
  useHelpInfo,
  usePhone,
} from "utils/hooks";
import { Paths } from "utils/objects";
import urls from "utils/urls";

import Tooltip from "../Tooltip";
import IconCaretDown from "./icons/caretDown.svg";
import IconCaretUp from "./icons/caretUp.svg";
import styles from "./styles.module.scss";

interface Props {
  children?: React.ReactNode;
  className?: string;
  configKey?: Paths<ApiHelpInfosGet> | string;
  contentClassName?: string;
  defaultCollapsed?: boolean;
  disableCollapse?: boolean;
  modal?: boolean;
  onClick?: React.MouseEventHandler;
  rightContent?: React.ReactNode;
  title?: React.ReactNode;
  toggleClassName?: string;
  withoutOverflow?: boolean;
  formGroup?: boolean;
}

const Accordion: React.FC<Props> = ({
  children,
  className,
  configKey = "",
  contentClassName,
  defaultCollapsed,
  disableCollapse,
  modal,
  onClick = () => null,
  rightContent,
  title,
  toggleClassName,
  withoutOverflow,
  formGroup,
}) => {
  const dispatch = useAppDispatch();
  const [helpInfoMessage, helpInfoLink] = useHelpInfo(configKey);
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const [showCopyIcon, setShowCopyIcon] = useState(false);
  const [section, subsection] = useAppSection();
  const backupVersion = useBackupVersion();
  const copy = useCopyToClipboard();
  const router = useRouter();
  const urlSection = backupVersion
    ? `backup/${backupVersion}/${section}`
    : section;
  const { t } = useTranslation();
  const privateKey = useAppSelector(selectPrivateKey);
  const isPhone = usePhone();

  const titleRef = useRef<HTMLHeadingElement>(null);

  const handleTitleHover = () => {
    setShowCopyIcon(true);
  };

  const handleTitleLeave = () => {
    setShowCopyIcon(false);
  };

  useEffect(() => {
    const accordionTitle = titleRef.current;
    if (accordionTitle) {
      accordionTitle.addEventListener("mouseenter", handleTitleHover);
      accordionTitle.addEventListener("mouseleave", handleTitleLeave);

      return () => {
        accordionTitle.removeEventListener("mouseenter", handleTitleHover);
        accordionTitle.removeEventListener("mouseleave", handleTitleLeave);
      };
    }
    return () => {};
  }, []);

  const accordionId = useMemo(
    () => decamelize(configKey.split(".").slice(-1)[0], { separator: "-" }),
    [configKey]
  );

  const copyUrl = urlJoin(
    urls.base,
    urls.appBuilder(privateKey, urlSection, subsection, accordionId)
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (disableCollapse) return;

      setIsCollapsed((prevState) => !prevState);

      onClick(event);
    },
    [disableCollapse, onClick]
  );

  return (
    <div
      className={classNames(
        styles.accordion,
        { [styles.collapsed]: isCollapsed || !children },
        className
      )}
      id={accordionId}
    >
      {title && (
        <div
          className={classNames(
            styles.toggle,
            { [styles.hasHelpInfoMessage]: !!helpInfoMessage },
            { [styles.withoutChildren]: !children },
            { [styles.withoutTitle]: !title },
            toggleClassName
          )}
        >
          <div className={styles.accordionTitleAndRadioButton}>
            <div className={classNames(styles.toggleButton)}>
              <div className={styles.titleAndCopyIconContainer} ref={titleRef}>
                <h3 className={styles.toggleButtonTitle}>{title}</h3>
                {!isPhone && privateKey && !modal && (
                  <Tooltip placement="top" label={t("common.copyLink")}>
                    <Button
                      allowOnPreviewMode
                      className={classNames(
                        styles.copyLink,
                        showCopyIcon && styles.showIcon
                      )}
                      onClick={() => {
                        copy(copyUrl);
                        router.push(copyUrl, undefined, {
                          shallow: true,
                        });
                      }}
                    >
                      <IconCopyLink />
                    </Button>
                  </Tooltip>
                )}
              </div>
              {helpInfoLink && (
                <Tooltip placement="top" label={t("common.documentation")}>
                  <span
                    className={styles.infoLink}
                    onClick={(e: React.MouseEvent) => {
                      e.stopPropagation();
                      dispatch(uiUpdated({ showDocs: helpInfoLink }));
                      dispatch(uiShowDocsKeyUpdated());
                    }}
                    onKeyDown={() => null}
                    role="button"
                    tabIndex={0}
                  >
                    <IconInfo />
                  </span>
                </Tooltip>
              )}
              {isPhone && privateKey && !modal && (
                <Button
                  allowOnPreviewMode
                  className={classNames(
                    styles.copyLink,
                    showCopyIcon && !backupVersion && styles.showIcon
                  )}
                  onClick={() => {
                    copy(copyUrl);
                    router.push(copyUrl, undefined, {
                      shallow: true,
                    });
                  }}
                  title={t("common.copyLink", { section: title })}
                >
                  <IconCopyLink />
                </Button>
              )}
            </div>
            {rightContent && (
              <div className={styles.toggleRight}>{rightContent}</div>
            )}
          </div>
          {!disableCollapse && (
            <Tooltip
              label={isCollapsed ? t("common.expand") : t("common.collapse")}
              placement="bottom"
            >
              <Button
                className={styles.toggleIconButton}
                onClick={handleClick}
                allowOnPreviewMode
              >
                {isCollapsed ? (
                  <IconCaretDown className={styles.toggleIcon} />
                ) : (
                  <IconCaretUp className={styles.toggleIcon} />
                )}
              </Button>
            </Tooltip>
          )}
        </div>
      )}

      <div
        className={classNames(
          styles.accordionContent,
          !title && styles.noTitle,
          !isCollapsed && !disableCollapse && styles.open,
          disableCollapse && styles.disableCollapse,
          disableCollapse && title && styles.withTitle,
          !helpInfoMessage && title && styles.withOutHelpInfoMessage,
          withoutOverflow && styles.withoutOverflow,
          formGroup && styles.form,
          contentClassName
        )}
      >
        {helpInfoMessage && configKey !== "overview.email" && (
          <Info>{helpInfoMessage}</Info>
        )}
        {children}
        {helpInfoMessage && configKey === "overview.email" && (
          <Info className={classNames(styles.contactHelpInfoMessageContainer)}>
            {helpInfoMessage}
          </Info>
        )}
      </div>
    </div>
  );
};

export default Accordion;
